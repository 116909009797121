import React, { Fragment, useEffect, useState } from 'react'
import LoaderCommon from '../Common/LoaderCommon'
import { decode } from 'html-entities'
import moment from 'moment'
import { Empty } from 'antd'
import authQueries from '../../queries/authQueries'
import PaginationComponent from '../Common/PaginationComponent'
import { useLoaderStore, usePaginationStore, userStore } from '../../store/userStore'
import LeadStatus from '../Common/LeadStatus'
import ProspectStatusList from '../Common/ProspectStatusList'
import FollowUp from '../Common/FollowUp'
import ApplicationClose from '../Common/ApplicationClose'
import { useApi } from '../Common/ApiProvider'
import AdvancedSearch from '../Common/AdvancedSearch'
import { useAdvancedSearch } from '../../store/advancedSearchStore'
import AdvancedSearchInactive from '../Common/AdvancedSearchInactive'
import { Link } from 'react-router-dom'
import { GanttChartSquare } from 'lucide-react'
import exportQueries from '../../queries/exportQueries'
import NotInterestedOrQualified from '../VisatypeCounts/NotInterestedOrQualified'
import { BASE_URL } from '../../apis/api'

const NotQualifiedListing = () => {

    const today = new Date()
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(today.getMonth() - 3);

    const [result, setResult] = useState([])
    const [isInitial, setIsInitial] = useState(false)

    const store = userStore()
    const { apiData, setApiDataAndNotify } = useApi();

    const data = useAdvancedSearch((state) => state);

    const currentPage = usePaginationStore((state) => state.currentPage);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);
    const loader = useLoaderStore((state) => state.loader);
    const setLoader = useLoaderStore((state) => state.setLoader);
    const resetPage = usePaginationStore((state) => state.resetPage);
    const resetAll = useAdvancedSearch((state)=> state.resetAll)

    const listApplicants = authQueries.useAllNotInterestOrQualifiedListMutation(
        (response) => {
            setLoader(false)
            setResult(response)
        }
    )

    useEffect(()=> {
        setLoader(true)
        let initial = true
        setIsInitial(true)
        const { name, email, mobile, visa, intake, office, district, reffered, appStatus } = data
        if(name || email || mobile || visa || intake || office || district || reffered || appStatus) {
            initial = false
            setIsInitial(false)
        }
        listApplicants.mutateAsync({
            page: currentPage,
            size: 25,
            ...data,
            status: 3,
            initial: initial,
            createdFrom: data?.createdFrom ? data?.createdFrom : initial ? moment(threeMonthsAgo).format("YYYY-MM-DD") : null,
        })
    },[apiData])


    const handleSearch = ({pagination}) => {
        setLoader(true)
        if(!pagination){
            resetPage()
        }
        let initial = true
        setIsInitial(true)
        const { name, email, mobile, visa, intake, office, district, reffered, appStatus } = data
        if(name || email || mobile || visa || intake || office || district || reffered || appStatus) {
            initial = false
            setIsInitial(false)
        }
        listApplicants.mutateAsync({
            page: currentPage,
            size: 25,
            ...data,
            status: 3,
            initial: initial,
            createdFrom: data?.createdFrom ? data?.createdFrom : initial ? moment(threeMonthsAgo).format("YYYY-MM-DD") : null,
        })
    }

    useEffect(() => {
        if(currentPage > 0){
            handleSearch({pagination: true})
        }
    }, [currentPage]);

    const ReassignCounselor = authQueries.useReAssignCounselorMutation(
        (res)=> {
            setApiDataAndNotify(res)
        }
    )

    const captureApplicant = authQueries.useCaptureApplicantTelecallerMutation(
        (res)=> {
            setApiDataAndNotify(res)
        }
    )

    const handleReAssign =(item)=> {
        if(store?.user?.role === "TELECALLER"){
            captureApplicant.mutateAsync(item?.id)
        }else{
            ReassignCounselor.mutateAsync(item?.id)
        }
    }

    const exportFile = exportQueries.useExportNotInterestedOrQualified(
        (res)=>{
            if(res?.success){
                window.open(`${BASE_URL}/api/impex/download/${res?.filename}`, '_blank')
            }
        }
    )

    const handleExport =()=>{
        let initial = true
        const { name, email, mobile, visa, intake, office, district, reffered, appStatus } = data
        if(name || email || mobile || visa || intake || office || district || reffered || appStatus) {
            initial = false
        }
        exportFile.mutateAsync({
            ...data,
            status: 3,
            initial: initial,
            createdFrom: data?.createdFrom ? data?.createdFrom : initial ? moment(threeMonthsAgo).format("YYYY-MM-DD") : null,
        })
    }

  return (
    <div>
        <div className="breadcrumb-and-otherbuttons">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Not qualified clients</li>
                </ol>
            </nav>
        </div>
        <div className="add_clients">
            <div className="flex items-center gap-[4px] mb-4">
                <i className="followup-icon">
                    <GanttChartSquare size="25" className='text-[#7D6FAB]' />
                </i>
                <div style={{display:'flex', flexDirection:'column'}}>
                    <div style={{display:'flex', alignItems:'center', gap:'10px'}}>
                        <span className="text-[16px] font-medium">Not qualified clients</span>	
                    </div>
                </div>
            </div>
            <div id="accordion" className="add_clients_accordion_form">
                <div className="card" id="appointments_wrpr">
                    <div className="card-header flex flex-row justify-between items-center">
                        <a className="collapsed card-link" href="" style={{ pointerEvents: 'none' }}>
                            Not qualified clients
                        </a>
                        <div className='flex gap-3'>
                            {
                                store?.user?.role === "CEO" &&
                                <div className='w-[100px]'>
                                    <button disabled={exportFile.isLoading} className='btn-customized-large' onClick={handleExport}>
                                        {exportFile.isLoading ? "Exporting..." : "Export"}
                                    </button>
                                </div>
                            }
                            <div className='w-[275px]'>
                                <NotInterestedOrQualified
                                label="qualified"
                                data={{
                                    ...data,
                                    status: 3,
                                    initial: isInitial,
                                    createdFrom: data?.createdFrom ? data?.createdFrom : isInitial ? moment(threeMonthsAgo).format("YYYY-MM-DD") : null
                                }}
                                />
                            </div>
                        </div>
                    </div>
                    <div id="appointment_any" className="collapse show" data-parent="#accordion">
                        <div className="card-body">
                            <div className="table-list-search-wrpr">
                                
                                <AdvancedSearchInactive handleSearch={handleSearch} />

                                <div className='flex flex-col justify-between'>
                                    <div className="search-results-count-wrpr">
                                        {
                                        result?.data?.totalItems > 0 ?
                                        `Search Results ${result?.data?.currentPage * 25 + 1}-${result?.data?.currentPage * 25 + result?.data?.items?.length} of ${result?.data?.totalItems}` :
                                        `O results found`
                                        }
                                    </div>
                                    <div className='text-[12px] italic mb-2 text-red-500'>
                                    Not qualified data is shown for the last 3 months. You can use the search function if you need older data
                                    </div>
                                </div>
                                {
                                    loader ?
                                    <LoaderCommon />:
                                    result?.data?.totalItems > 0 ?
                                    <div className="table-wrpr">
                                        <div className="table-section">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <table className="table prospect-table">
                                                        <thead>
                                                            <tr className='alt-colors'>
                                                                <th width="30">#</th>
                                                                <th>Profile Status</th>
                                                                <th>Office</th>
                                                                <th>Date Created</th>
                                                                <th>Applicant Name</th>
                                                                <th>Contact Number</th>
                                                                <th>Visa Type</th>
                                                                <th>Intake</th>
                                                                <th>Reg. Date</th>
                                                                <th>Prospect Status</th>
                                                                <th>Not Qualified On</th>
                                                                <th>Counselor</th>
                                                                <th>Referred By</th>
                                                                <th>&nbsp;</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="ui-sortable">
                                                            {result?.data?.items?.map((element, index) =>{
                                                                const item = element
                                                                return(
                                                                <Fragment key={index}>
                                                                    <tr>
                                                                        <th className="">{(index + 1) + (result.data?.currentPage * 25)}</th>
                                                                        <td>
                                                                            <LeadStatus item={item} />
                                                                        </td>
                                                                        <td>{item?.office?.name}</td>
                                                                        <td>{moment(item?.createdAt).format('DD-MM-YYYY')}</td>
                                                                        <td>{decode(item?.applicant_name)} {decode(item?.middlename)} {decode(item?.lastname)}</td>
                                                                        <td>
                                                                            <div className="email-mobile flex flex-col">
                                                                                <div>{item?.mobile_no}</div>
                                                                                <div>{item?.landline_no}</div>
                                                                                <div>{item?.whatsapp_no}</div>
                                                                            </div>
                                                                        </td>
                                                                        <td>{item?.visatype?.description}</td>
                                                                        <td>{item?.intake?.intake}</td>
                                                                        <td>
                                                                            {(item?.registered_status === "YES" && item?.registered_date) ? <span className="registered">{moment(item?.registered_date).format('DD-MM-YYYY')}</span> : '-'}
                                                                        </td>
                                                                        <td>
                                                                            <ProspectStatusList defaultStatus={"Not qualified"} item={item} readonly={true}/>
                                                                        </td>
                                                                        <td>
                                                                            <span className="notinterested">
                                                                            {moment(element?.statusAt).format("DD-MM-YYYY")}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            {userFilter(item?.assignUser)}
                                                                        </td>
                                                                        <td>{item?.refferal?.refferal}</td>
                                                                        <td className='flex justify-end gap-1'>
                                                                            <div className='w-[130px]'>
                                                                                <FollowUp item={item} statusHidden={true}/>
                                                                            </div>
                                                                            {(store?.user?.role === "COUNSELOR" || store?.user?.role === "TELECALLER") &&
                                                                            <div className='w-[175px]'>
                                                                                <button
                                                                                disabled={ReassignCounselor.isLoading ? true : false}
                                                                                onClick={()=> {handleReAssign(item)}}
                                                                                className="btn-customized">
                                                                                    Capture - Not Qualified Clients
                                                                                </button>
                                                                            </div>
                                                                            }
                                                                            {
                                                                                (store?.user?.role === "COUNSELOR" || store?.user?.role === "SENIORPROGRAMMANAGER") &&
                                                                                <div className='w-[50px]'>
                                                                                    <ApplicationClose item={item} />
                                                                                </div>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                </Fragment>

                                                            )})}
                                                        </tbody>
                                                    </table>
                                                    <PaginationComponent result={result} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                    <div className='mt-24 mb-24'>
                                        <Empty
                                        description={<div className="text-sm text-blue-700" style={{fontFamily: "var(--primary-font)"}}>Clients not found!</div>}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default NotQualifiedListing

const userFilter =(user)=>{
    if(!user) return null
    return user?.CounselorAssign?.userdetail?.firstname + ' ' + user?.CounselorAssign?.userdetail?.lastname
}